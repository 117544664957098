import React from "react";
import "./Home.css";
import Houl from "../images/houl.svg";
import debouncingBlog from "../images/debouncing_blog.png";
import substack from "../images/substack_thumbnail.jpg";
import Frittr from "../images/frittr.png";
import Windys from "../images/windys.png";
import Dyme from "../images/dyme.png";
import { Helmet } from "react-helmet";
import NavTab from "./NavTab";
import Skills from "./Skills";
import Footer from "./Footer";
import { Link } from "react-router-dom";
// React Slick Carousel Dependencies
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  // Carousel items data
  const carouselItems = [
    {
      image: Houl,
      title: "Houl",
      description:
        "The Ultimate creator-centred Live Streaming platform optimized for effortless monetization.",
      link: "https://houl.tech/",
    },
    {
      image: Dyme,
      title: "Dyme",
      description:
        "Social Media app which makes you money based on your content.",
      link: "https://dymesocial.com/",
    },
    {
      image: substack,
      title:
        "The Rise of Aggregator Businesses: A Revolutionary Yet Costly Innovation",
      description:
        "World is in desperate need of lean or no-commissions model.",
      link: "https://prashantscripts.substack.com/p/the-rise-of-aggregator-businesses",
    },
    {
      image: debouncingBlog,
      title: "Debouncing & Throttling in Javascript",
      description: "What are they and how do we optimise our web applications?",
      link: "https://prashantxwrites.hashnode.dev/debouncing-and-throttling-in-javascript",
    },
  ];

  // React Slick Carousel settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div>
      <Helmet>
        <title>Prashant Singh Chauhan</title>
      </Helmet>
      <NavTab />

      {/* Intro Section */}
      <div className="introParent">
        <div className="text">
          <h1>Hi there!</h1>
          <h2>I'm Prashant</h2>
          <span>
            {" "}
            building <b style={{ color: "purple", fontSize: "1.4rem" }}>Houl</b>
            , a live-streaming platform like never before designed to empower
            creators with
            <span style={{ color: "black" }}>
              {" "}
              effortless monetisation, content promotion & unmatched
              sponsorships!
            </span>
          </span>

          <p>
            I am a techie turned founder with over 5 years of tech experience,
            now pursuing entrepreneurship where I learn new stuff at every
            corner and try to document it here on my blogs.
          </p>
          <p style={{ fontWeight: 500 }}>
            Your go-to innovator, problem-solver, and creator advocate! 🚀
          </p>
        </div>

        {/* Carousel Section */}

        <div className="carousel">
          <Slider {...sliderSettings}>
            {carouselItems.map((item, index) => (
              <div key={index} className="carouselItem">
                <img
                  src={item.image}
                  alt={item.title}
                  className="carouselImage"
                />

                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="carouselLink"
                >
                  Visit
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* Blogs Section */}
      <div className="project">
        <h2>
          Fresh <span>Blogs 🍃</span>
        </h2>

        <div className="cardParent">
          {" "}
          <div className="card">
            <h3>Debouncing & Throttling in Javascript</h3>
            <small>21st Jan 2025</small>
            <div className="proj-preview">
              <img
                src={substack}
                alt="The Rise of Aggregator Businesses: A Revolutionary Yet Costly Innovation"
              />
            </div>
            <p>World is in desperate need of lean or no-commissions model.</p>

            <div className="code">
              <a
                href="https://prashantscripts.substack.com/p/the-rise-of-aggregator-businesses"
                target="_blank"
                rel="noreferrer noopener"
                className="live"
                style={{ padding: "0.25rem 1.5rem" }}
              >
                <strong>Read</strong>
              </a>
            </div>
          </div>
          <div className="card">
            <h3>Debouncing & Throttling in Javascript</h3>
            <small>6th June 2022</small>
            <div className="proj-preview">
              <img
                src={debouncingBlog}
                alt="Debouncing & Throttling in Javascript"
              />
            </div>
            <p>
              What are they and how do we use them to implement optimization in
              our web applications?
            </p>

            <div className="code">
              <a
                href="https://prashantxwrites.hashnode.dev/debouncing-and-throttling-in-javascript"
                target="_blank"
                rel="noreferrer noopener"
                className="live"
                style={{ padding: "0.25rem 1.5rem" }}
              >
                <strong>Read</strong>
              </a>
            </div>
          </div>
        </div>
        <Link exact to="/blogs" className="viewA">
          View All
        </Link>
      </div>
      {/* Projects Section */}
      <div className="project">
        <h2>
          Hot <span>Projects 🔥</span>
        </h2>
        <div className="cardParent">
          <div className="card">
            <h3>Dyme</h3>
            <small>10th March 2024</small>
            <div className="proj-preview">
              <img src={Dyme} alt="Dyme" />
            </div>
            <p>Social Media app which makes you money based on your content.</p>
            <div className="techStack">
              <h6>Firebase</h6>
              <h6>Chakra UI</h6>
              <h6>MERN</h6>
            </div>
            <div className="code">
              <a
                href="https://dymesocial.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="live"
                style={{ padding: "0.25rem 1.5rem" }}
              >
                <strong> Join Dyme</strong>
              </a>
              <a className="source">Private Project</a>
            </div>
          </div>

          <div className="card">
            <h3>Frittr</h3>
            <small>11th September 2022</small>
            <div className="proj-preview">
              <img src={Frittr} alt="Frittr" />
            </div>
            <p>Social Media app for Foodies, made using React JS</p>
            <div className="techStack">
              <h6>Redux Toolkit</h6>
              <h6>MERN</h6>
            </div>
            <div className="code">
              <a
                href="https://frittr.netlify.app/"
                target="_blank"
                rel="noreferrer noopener"
                className="live"
              >
                View Live
              </a>
              <a
                href="https://github.com/percius47/fritter"
                target="_blank"
                rel="noreferrer noopener"
                className="source"
              >
                View Source
              </a>
            </div>
          </div>

          <div className="card">
            <h3>Windy's</h3>
            <small>11th August 2022</small>
            <div className="proj-preview">
              <img src={Windys} alt="Windys" />
            </div>
            <p>Food Ordering app, made using React JS</p>
            <div className="techStack">
              <h6>MERN</h6>
              <h6>Razorpay API</h6>
            </div>

            <div className="code">
              <a
                href="https://windys.netlify.app/"
                target="_blank"
                rel="noreferrer noopener"
                className="live"
              >
                View Live
              </a>
              <a
                href="https://github.com/percius47/ecommerce-mockman"
                target="_blank"
                rel="noreferrer noopener"
                className="source"
              >
                View Source
              </a>
            </div>
          </div>
        </div>
        <Link exact to="/projects" className="viewA">
          View All
        </Link>
      </div>

      {/* <Skills /> */}
      <Footer />
    </div>
  );
}

export default Home;
